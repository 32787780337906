<template>
  <div :style="{
    height: '100%',
    '--themeColor': $store.state.themeInfo.themeColor
  }">
    <van-sticky>
      <head-bar type="primary" title="动态" @onClickLeft="onClickLeft" />
    </van-sticky>
    <van-skeleton class="mt-20" title :row="3" :loading="loading"/>
    <div v-if="containsPage && !loading && conIt" :style="{ height: pageItem[pageKey].type === 3 || pageItem[pageKey].type === 4 ? 'calc(100% - 1.226rem)' : '100%' }">
      <div class="page-bg full-h">
      <!-- 图文类 -->
      <div v-if="pageItem[pageKey].type === 1" v-html="pageItem[pageKey].content"></div>
      <!-- 视频类 -->
      <div v-if="pageItem[pageKey].type === 2">
        <video class="full-w video" :src="pageItem[pageKey].videoUrl" :poster="pageItem[pageKey].poster" controls></video>
      </div>
      <!-- 日程类 -->
      <div v-if="pageItem[pageKey].type === 3">
        <template-one v-if="pageItem[pageKey].content.title" :scheduleData="pageItem[pageKey].content" />
        <van-empty v-else description="暂无数据"/>
      </div>
      <!-- 文件类 -->
      <div class="file-container" v-if="pageItem[pageKey].type === 4">
        <div
          class="file-item"
          v-for="(item, index) in pageItem[pageKey].content"
          :key="index"
          @click="viewFile(item.fileUrl)"
        >
          <img :src="require('@/assets/images/icon/file-icon.png')" alt="">
          <span class="text-ellipsis">{{ item.fileName }}</span>
        </div>
      </div>
      <!-- 访问 -->
      <!-- <div style="color: #ccc;font-size: 14px;margin-top: 20px;">阅读 {{visitVolume}}</div> -->
      </div>
    </div>
    <no-page v-if="!containsPage" />
  </div>
</template>

<script>
import templateOne from '@/components/scheduleTemplates/templateOne'
import noPage from '@/components/noPage/noPage'
import headBar from '@/components/headBar/headBar'
import { Dialog } from 'vant'
export default {
  name: 'selfPage',
  components: { noPage, headBar, templateOne },
  data() {
    return {
      containsPage: false,
      loading: true,
      miceLink: '',
      pageItem: {},
      pageKey: '',
      conIt: false,
      visitVolume: 0
    }
  },
  mounted() {
    if (this.$route.params.linkname) {
      this.pageKey = this.$route.params.linkname
      this.miceLink = this.$route.params.miceLink
      this.containsPage = true
      this.getContent()
      this.getWzVisit()
    } else {
      this.containsPage = false
    }
  },
  methods: {
    async getContent(){ // 获取自建页面信息
      this.loading = this.containsPage = true
      let result = await this.$http.get(this.$baseURL.index.getSelfPageContent() + '/' + this.pageKey, 1)
      this.loading = false
      if (result.code === 200) {
        if (result.data === null) { // 自建页面删除后处理
          this.containsPage = false
          return
        }
        const page = {}
        page.type = result.data.type
        page.title = result.data.title
        if (page.type === 1) {
          page.content = this.$utils.handleLargerImg(/\w+\.(jpg|png|jpeg|gif|webp)/ig, '?imageView2/2/w/750', '', result.data.content)
          // this.content = result.data.content
        } else if (page.type === 2) {
          page.videoUrl = result.data.videoUrl
          page.poster = result.data.videoCoverUrl
        } else {
          page.content = JSON.parse(result.data.content)
        }
        this.pageItem[this.pageKey] = page
        this.conIt = true
        this.visitVolume = result.data.visitVolume
      } else {
        Dialog.alert({
          title: '提示',
          message: result.message,
        }).then(() => {
          // on close
        })
      }
    },
    onClickLeft() {
      this.$parent.onChange(0)
    },
    viewFile(url) {
      window.location.href = url
    },
    // 微站访问链接
    getWzVisit() {
      this.$http.post(this.$baseURL.index.getWzVisit(), { linkName: this.pageKey },  1)
    }
  },
  watch: {
    $route(to) {
      if (to.fullPath.indexOf('selfpage') !== -1) {
        this.pageKey = to.params.linkname
        if (!this.pageItem[this.pageKey]) {
          this.conIt = false
          this.getContent()
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
@themeColor: var(--themeColor);
/deep/ .van-skeleton__row, .van-skeleton__title{
  background: #dedede;
}
p{
  margin: 0;
}
.page-bg{
  height: 100%;
  padding-bottom: 60px;
  overflow-x: hidden;
  font-size: 14px;
  line-height: 22px;
  // background: #f2f8fc;
  // /deep/div{
  //   background: #fff;
  //   padding: 10px;
  //   border-radius: 8px;
  //   box-shadow: 0px 1px 2px 0px rgba(183, 180, 188, 0.14);
  //   margin-bottom: 10px;
  // }
  /deep/ p{
    margin: 0;
    // span{
    //   position: relative;
    //   &::before{
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     height: 100%;
    //     border-left: 3px solid #dedede;
    //   }
    // }
  }
  /deep/ img{
    margin: 3px 0;
    max-width: 100%!important;
    height: auto;
    border-radius: 8px;
  }
  /deep/ video {
    width: 100%;
  }
}
.file-container{
  // display: flex;
  // justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  padding: 0.3rem 0.3rem;
  overflow-y: auto;
  .file-item{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 3.2rem;
    width: calc(50% - 0.8rem);
    flex: 1;
    margin: 0.4rem;
    border-radius: 8px;
    background: @themeColor;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
    img{
      height: 0.9rem;
      width: 0.9rem;
    }
    span{
      display: inline-block;
      max-width: 100%;
      text-align: center;
      margin-top: 0.5rem;
      padding: 0 0.4rem;
      color: #fff;
      font-size: 0.3rem;
    }
  }
}
.video{
  height: 200px;
  object-fit: fill;
}
</style>